<template>
    <div>
        <div class="hero_video">
            <video autoplay muted loop class="video-background" playsinline>
                <source  :src="`${baseURL}/images/video/CDI_Banner_2024.mp4`" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
        <br/>

        <div class="row text-center bg-dark home_slogan">
            <div class="col-sm-12">
                <div class="slogan">Custom Curb Adapters - New Construction Curbs - Isolation Curbs - Conforming/Calculated Curbs - Accessories - More</div>
            </div>
        </div>
        <div class="col-sm-10 col-sm-offset-1 home_link_boxes_container">
            <div class="col-lg-4 home_link_boxes">
                <div class="panel shadow">
                    <div class="panel-body">
                        <a class="home_circle_text" href="/my/register">
                            <div class="home_circle_text">Create Account</div>
                            <div class="circle-icon">
                                <span class="glyphicon glyphicon-user home_icon"></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 home_link_boxes">
                <div class="panel shadow">
                    <div class="panel-body">
                        <div class="home_circle_text">
                            <router-link :to="`/quote/new`">
                                Create a Quote</router-link>
                            <div class="circle-icon">
                            <span class="glyphicon glyphicon-edit home_icon"></span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 home_link_boxes">
                <div class="panel shadow">
                    <div class="panel-body">
                        <a class="home_circle_text" href="/employment">
                            <div class="home_circle_text">Join Our Team</div>
                            <div class="circle-icon">
                                <span class="glyphicon glyphicon-briefcase home_icon"></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {store} from "@/store/BusinessObject.store";
export default {
    name: 'home',
    components: {},
    data() {
        return {
            appState: this.$appStore.state,
            state: store.state,
            baseURL: process.env.VUE_APP_URL
        }
    },
}
</script>

<style scoped>


.hero_image {
    background-image: url('../assets/hero-setting-curb.jpg');
    background-size: cover;
    margin-top: -16px;

}


/*
.hero_image {
    background-image: url('https://cdicurbs.com/images/v4/cdi_LaborDay_slider082824.jpg');
    background-size: cover;
}

.jumbotron_inner {
    display: none;
}

 */

@media screen and (max-width: 1220px) {
     .hero_image{
        background-size: contain;
         background-repeat: no-repeat;
        background-position: center;
         width: 100%!important;
     }
     .hero{
         min-height: 300px;
     }
 }

.bg-dark {
    background: #333333;
    color: #FFFFFF;
}



</style>