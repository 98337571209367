import http from "axios";
import helpers from "./helpers";
import { cdiVars } from '@/cdiVars';

export default {
    state: {
        user: {
            defaultLocationID: null,
            roles: {},
            userID: null,
            userName: null,
            companyName: null,
            userTypeID: null,
            autoDrawingUserTeam: null,
            fileAccessToken: null,
            schedulerLocation: null,
            termsDate: null,
        },
        loggedIn: false,
        termsDate: '',
        appMessage: '',
        errorText: '',
        warningText: '',
        toast: {
            text: '',
            link: '',
        },
        fileUploadLocation: '',
        fileUploadContext: null,
        fileUploadModalOpen: false,
        fileUploadContextData: {
            referenceID: 0,
            reference2ID: 0,
            conformingTypeID: 0,
            data: {},
            metadata: {}
        },
        massFileUploadContext: null,
        fileUploadCallback: function () {
        },
        fileMassUploadCallback: function () {
        },
        alerts: [],
        recentQuotes: [],
        recentOrders: [],
        showPublicNav: true,

        /** Track view on a global level for file upload */
        fileCallbackView: null,
        synchronizeFileCallbackView: false
    },

    toggleShowNav: function (){
        this.state.showPublicNav = !this.state.showPublicNav;
    },
    
    signIn: function (formFields) {
        let qsString = helpers.getQSString(helpers.getQSPairs(formFields));
        
        http.post(`${ cdiVars.API_URL }/mycdi/jwt_signin`, qsString).then(
            ((res) => {
                if (res.data) {
                    let tokenString = JSON.stringify(res.data);
                    let tokenJSON = JSON.parse(tokenString);
                    localStorage.setItem('token', tokenJSON.token);
                    
                    this.loadToken();
                } else {
                    this.signOut();
                }
            })

        ).catch(() => {
            this.errorBox('Sign in failed! Check your user name and password or reset your password.')
        });
    },
    signOut: function (redirect = '/') {
        http.post(`${ cdiVars.API_URL }/myuser/delete_file_access_token/${ this.state.user.userID }`, '', { headers: helpers.authHeader() })
            .then(()=> {
                    this.state.user = {roles: {}};
                    this.state.loggedIn = false;
                    this.state.termsDate = '';
                    localStorage.removeItem('token');
                    window.location.replace(redirect);
                }
            );
    },

    autoSignOut: function() {
        this.signOut();
        this.errorBox('Your Session has expired. Please log in again.')
    },

    loadToken: function () {
        let token = localStorage.getItem('token');
        token = (token !== 'undefined') ? token : null;
        if (token) {
            let payload = JSON.parse(atob(token.split('.')[1]));
            this.state.user = (payload.data);
            if (this.state.user.companyName == 'no company' || this.state.user.userTypeID == 5){
                this.signOut('/new_user_r');
            }
            this.state.loggedIn = true;
            this.state.termsDate = (payload.data.termsDate);
        }
    },
    
    loadingMessage: function () {
        this.state.appMessage = '<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> Loading...';
    },
    successBox: function (msg) {
        this.state.appMessage = msg;
        if (msg) {
            setTimeout(() => this.state.appMessage = '', 1500);
        }
    },
    
    errorBox: function (msg) {
        this.state.appMessage = '';
        this.state.errorText = msg;
    },
    warningBox: function (msg) {
        this.state.appMessage = '';
        this.state.warningText = msg;
    },
    toast: function(txt, link = '') {
        this.state.toast.text = txt;
        this.state.toast.link = link;
    },
    setFileUploadContext: function (context) {
        this.state.fileUploadContext = context;
    },
    setFileMassUploadContext: function (context) {
        this.state.massFileUploadContext = context;
    },
    setFileUploadContextData: function (data) {
        let referenceID = parseInt(data.referenceID);
        let reference2ID = parseInt(data.reference2ID);
        let conformingTypeID = parseInt(data.conformingTypeID);
        delete data.referenceID;
        delete data.reference2ID;
        delete data.conformingTypeID;


        this.state.fileUploadContextData = {
            referenceID: referenceID ? referenceID : 0,
            reference2ID: reference2ID ? reference2ID : 0,
            conformingTypeID: conformingTypeID? conformingTypeID : 0,
            ...data
        };
    },

    /**
     * @param {Function} fn
     */
    setFileUploadCallback: function (fn) {
        this.state.fileUploadCallback = fn;
    },
    setFileMassUploadCallback: function (fn) {
        this.state.fileMassUploadCallback = fn;
    },
    hasRole: function (allowedRoles) {
        let userRoles = Object.values(this.state.user.roles)

        if (userRoles.includes(cdiVars.CDI_ROLE_SUPER)) {
            return true;
        }

        if (Array.isArray(allowedRoles)) {
            for (let i = 0; i < allowedRoles.length; i++) {
                if (userRoles.includes(allowedRoles[i])) {
                    return true;
                }
            }
            return false
        } else {
            return userRoles.includes(allowedRoles);
        }
    },
    
    isStaff: function () {
        return this.state.user.userTypeID == cdiVars.USER_TYPE_EMPLOYEE;
    },
    
    isStaffExcludingShop: function () {
        return this.hasRole([
            cdiVars.CDI_ROLE_HR,
            cdiVars.CDI_ROLE_SALES,
            cdiVars.CDI_ROLE_ACCOUNTING,
            cdiVars.CDI_ROLE_ENG,
            cdiVars.CDI_ROLE_QC,
            cdiVars.CDI_ROLE_SHOP_SUP,
            cdiVars.CDI_ROLE_LOGISTICS,
            cdiVars.CDI_ROLE_MATERIAL
        ]);
    },

    isContractor: function() {
        return this.state.user.userTypeID == cdiVars.USER_TYPE_CONTRACTOR;
    },
    
    validateEmail: function (email) {
        var regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    },
    
    setTitle: function(titleAr = []) {
        titleAr.push('CDI Curbs');
        document.title = titleAr.join(' - ');
    },
    
    setUserFileAccessToken: function (token) {
        this.state.user.fileAccessToken = token;
    },

    getUserFileAccessToken: function () {
        return this.state.user.fileAccessToken;
    },

    getAlerts: function () {
        http.post(`${ cdiVars.API_URL }/mycdi/unread_alerts`, '', { headers: helpers.authHeader() })
            .then(response => this.state.alerts = response.data.alerts);
    },
    refreshFileAccessToken: function() {
        http.post(`${ cdiVars.API_URL }/mycdi/regenerate_file_access_token`, {}, {headers: helpers.authHeader() }).then(result => {
            this.setUserFileAccessToken(result.data.token);
        });
    },
    readAlert: function(alertID) {
        http.post(`${ cdiVars.API_URL }/myuser/mark_alert_read`, `params=${alertID}`, { headers: helpers.authHeader() })
            .then(() => this.getAlerts());
    },
    recentQuotesOrders: function() {
        let quoteParams = {
            "params[page_size]": "2",
            "params[direction]": 'DESC',
            "params[orderby]": 'quote_date',
            "params[show_my_ccs]": 'on',
        };
        http.post(`${ cdiVars.API_REPORT_URL }/quotes/to_json`, helpers.getQSString(quoteParams), { headers: helpers.authHeader() })
            .then(response => this.state.recentQuotes = response.data.rows.map(quote => quote.quote_id));

        let trackerParams = {
            "params[page_size]": "2",
            "params[direction]": 'DESC',
            "params[orderby]": 'tracker_date',
            "params[show_my_ccs]": 'on',
        };
        http.post(`${ cdiVars.API_REPORT_URL }/trackers/to_json`, helpers.getQSString(trackerParams), { headers: helpers.authHeader() })
            .then(response => this.state.recentOrders = response.data.rows.map(tracker => tracker.tracker_id));
    },

    validateFileAccess() {
        http.post(`${ cdiVars.API_URL }/mycdi/has_valid_file_access_token`, {
            'params[token]': this.getUserFileAccessToken()
        },{
            headers: helpers.authHeader()
        }).then(result => {
            if (!result.valid) {
                http.post(`${ cdiVars.API_URL }/mycdi/regenerate_file_access_token`, {}, {headers: helpers.authHeader() }).then(result => {
                    this.setUserFileAccessToken(result.data.token);
                });
            }
        });
    }
}